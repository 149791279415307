import queryString from 'query-string';

export const onClientEntry = () => {
  
    const parseUrl = (params) => {
      const queryParams = queryString.parse(params);
      /* eslint-disable camelcase */
      let { utm_source } = queryParams;
      if (utm_source) {
        utm_source = utm_source
          .split('_')
          .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
      }
      return utm_source || document.referrer;
    };

    const referringUrl = JSON.parse(
        window.localStorage.getItem('referringUrlMyelomaUpdate')
      )?.data;

  
    if (!referringUrl) {
      window.localStorage.setItem(
        'referringUrlMyelomaUpdate',
        JSON.stringify({
          data: parseUrl(window.location.search) || 'Direct Navigation'
        })
      );
    }
  };